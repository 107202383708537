/* Custom Stylesheet */

/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */

a {
  color: #039be5;
  text-decoration: none;
}

.header {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.container {
  width: 80%;
  margin: 0 auto;
}

#hamburger {
  display: none;
}

/* 卡片 */

.cardFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding-left: 2%;
}

.cardFlex div {
  margin-top: 40px;
  width: 23.5%;
  margin-right: 1%;
  padding: 10px 10px 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.cardFlex div img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.cardFlex div>span {
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
}

.cardFlex div p {
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  padding: 2rem 20px;
  margin-bottom: 20px;
}

.cardFlex div p span {
  border: 1px solid #555;
  padding: 2px 5px;
  border-radius: 5px;
  margin-top: 5px;
}

.cardFlex div a {
  margin: 20px;
  color: #ffab40;
}

/* 知识卡片 */

.cardFlex li {
  width: 32%;
  margin-right: 1%;
}

.nav-left {
  float: right;
  display: block;
  margin: 0;
}

.nav-left li {
  cursor: pointer;
  float: left;
}

.nav-left li a {
  display: inline-block;
  color: #fff;
  padding: 0 15px;
}

.nav-left li.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-left li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* demo */

.flexUl ul {
  display: flex;
}

.flexUl ul li {
  width: 30%;
  text-align: center;
  padding-bottom: 15px;
  cursor: pointer;
  color: #2c4fcd;
}

.demo .demo_iframe {
  position: relative;
}

.demo iframe {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.iframe{
  width: 100%;
  height: 100%;;
}

/* 詳情頁 */

.details {
  position: relative;
}

.details .keys span {
  padding: 8px 14px;
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 16px;
}

/* 参考资料 */

.resources ul li {
  list-style: disc;
}

@media only screen and (max-width: 1250px) {
  .cardFlex div {
    width: 32%;
    margin-right: 1%;
  }
}

@media only screen and (min-width: 675px) {
  .nav-left {
    display: block !important;
  }
}

@media only screen and (max-width: 675px) {
  .container {
    width: 100%;
    padding: 0 10px;
  }
  #hamburger {
    display: block;
    float: right;
    overflow: hidden;
    vertical-align: middle;
    margin: 10.5px 20px;
    z-index: 100;
    position: relative;
    cursor: pointer;
  }
  #hamburger span {
    width: 30px;
    height: 5px;
    margin: 5px 0;
    background-color: #fff;
    display: block;
    border-radius: 2px;
    -webkit-transition: .3s;
    transition: .3s;
  }
  .nav-left {
    display: none;
    position: absolute;
    top: 56px;
    left: 0;
    text-align: center;
    z-index: 99;
    background-color: #2c4fcd;
    width: 100%;
    overflow: hidden;
    border-top: 1px solid #fff;
  }
  .nav-left li {
    width: 100%;
  }
  /* 卡片 */
  .cardFlex div {
    width: 49%;
    margin-right: 1%;
  }
  .cardFlex li {
    width: 100%;
    margin: 0 1%;
  }
}